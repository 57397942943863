function initGrecaptcha() {
  if (!window.grecaptchaLoaded) {
    var tag = document.createElement("script");
    tag.src =
      "https://www.google.com/recaptcha/api.js?render=6Le3RbYUAAAAAPZv5dcDACVh2OpgHyq25bAkzWjn";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.grecaptchaLoaded = true;
  }
}

window.initGrecaptcha = initGrecaptcha;

function onSubmitForm(ev, form, inputId, btnId, action) {
  ev.preventDefault();
  const btn = document.getElementById(btnId);

  if (btn && btn.disabled == false) {
    btn.disabled = true;

    grecaptcha
      .execute("6Le3RbYUAAAAAPZv5dcDACVh2OpgHyq25bAkzWjn", { action })
      .then(function (token) {
        document.getElementById(inputId).value = token;

        form.submit();
      });
  }

  return false;
}

window.onSubmitForm = onSubmitForm;
